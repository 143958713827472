import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'
import { OneData, OneDataProps } from '../../Cards/OneData'
import styles from './../../LiveMonitor.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultModalStyles } from '../../../../../../constants'
import { copyToClipboard } from '../../../../../../helpers/utilities'
import { useUIContext } from '../../../../../../context/UIContext'
import { AttendeesNotInSession } from '../../../../../../apis/api.participation'

export const AttendanceLiveCard = () => {
    const { t } = useTranslation('teamMonitor')
    const [isOpen, setIsOpen] = useState(false)

    const { stats } = useLiveMonitor()
    if (!stats) return null
    const { totalStudents } = stats.monitor.teams
    const { attendeesNotInSession } = stats.monitor.attendance

    const attendanceInfo: OneDataProps = {
        title: (
            <>
                {t('People')} <span className={styles.boldRed}>LIVE</span>
            </>
        ),
        info: t(
            'People connected and attendees pending entry to the video call. Click the link below to see the list of people who are not part of the team.'
        ),
        caption: '',
        data: totalStudents,
        footerIcon: ['fas', 'video-slash'],
        footerText: (
            <button
                className={styles.link}
                onClick={() => setIsOpen(() => true)}
                disabled={!attendeesNotInSession.length}
            >
                {t('People')} <span className={styles.bold}>{t('without entering the team')}</span>
            </button>
        ),
        footerData: <span className={styles.bold}>{attendeesNotInSession.length}</span>,
    }

    return (
        <>
            <OneData {...attendanceInfo} />
            {isOpen && (
                <AttendanceModal
                    {...{
                        isOpen,
                        closeModal: () => setIsOpen(() => false),
                        attendeesNotInSession,
                    }}
                />
            )}
        </>
    )
}

const AttendanceModal = ({
    isOpen,
    closeModal,
    attendeesNotInSession,
}: {
    isOpen: boolean
    closeModal: () => void
    attendeesNotInSession: AttendeesNotInSession[]
}) => {
    const { t } = useTranslation('teamMonitor')
    const { toastNotification } = useUIContext()

    const onCopy = (email: string) => {
        copyToClipboard(email)
        toastNotification(t('Email copied to clipboard.'))
    }

    return (
        <Modal isOpen={isOpen} style={defaultModalStyles}>
            <FontAwesomeIcon
                icon={['fas', 'times']}
                onClick={closeModal}
                className={styles.closeIcon}
            />
            <div className={styles.modal}>
                <div className={styles.actions}>
                    <div className={styles.title}>
                        <FontAwesomeIcon icon={['fas', 'users']} />
                        <h4>{t('People without entering the team')}</h4>
                    </div>
                    {/* <button onClick={refreshInfo} className="white">
                        <FontAwesomeIcon icon={['fas', 'arrow-rotate-right']} />
                    </button> */}
                </div>
                <div className={styles.table}>
                    {attendeesNotInSession.map(({ displayName, email, team }, index) => {
                        return (
                            <div key={index} className={styles.attendanceRow}>
                                <div>{displayName}</div>
                                <div>
                                    {email}
                                    <FontAwesomeIcon
                                        icon={['fas', 'copy']}
                                        onClick={() => onCopy(email)}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </div>
                                <div className={styles.itemCenter}>{team}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Modal>
    )
}
