import React from 'react'
import { ProgressBarsProps, ProgressBars } from '../../Cards/ProgressBars'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'
import { useTranslation } from 'react-i18next'

export const AttendanceProgressCard = () => {
    const { t } = useTranslation('teamMonitor')
    const { stats } = useLiveMonitor()
    if (!stats) return null
    const { attendance } = stats.monitor

    const { actives, present, presentPercentage, absent, absentPercentage } = attendance

    const teamsInfo: ProgressBarsProps = {
        title: t('Assistance'),
        subtitle:
            actives &&
            `${t(`Out of a total of {{ totalStudents }} who started`, {
                totalStudents: actives,
            })}`,
        info: t('Attendance status: present and absent participants in the video call.'),
        metrics: [
            {
                icon: ['fas', 'user-check'],
                caption: `${t('Assistants')} ${present}`,
                value: Intl.NumberFormat().format(present),
                percentage: presentPercentage,
                variant: 'success',
            },
            {
                icon: ['fas', 'user-clock'],
                caption: `${t('Absentees')} ${absent}`,
                value: Intl.NumberFormat().format(absent),
                percentage: absentPercentage,
                variant: 'danger',
            },
        ],
    }
    return <ProgressBars {...teamsInfo} />
}
